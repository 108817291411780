import { InputBase, InputBaseProps } from '@mui/material';
import { sx } from '../../../util/sx.js';

const styles = sx({
  root: {
    borderRadius: 0.5,
    outline: (theme) => `2px solid ${theme.palette.primary.main}`,
    '& .MuiInputBase-input': {
      padding: '12px 8px',
      color: '#172B4D',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '17px',
    },
  },
  hover: {
    ':hover': {
      cursor: 'pointer',
    },
  },
});

export const CellInput = ({ sx, ...props }: InputBaseProps) => {
  return (
    <InputBase autoFocus fullWidth sx={{ ...styles.root, ...sx }} {...props} />
  );
};
